import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { ImageGrid } from "../components/ImageGrid"
import { Hero } from "../components/Hero"
import { TextColumns } from "../components/TextColumns"
import { StyledFlex } from "../components/StyledFlex"
import { Image } from "../components/Image"

let img = []
for (let i = 1; i <= 15; i++) {
  img.push(`ConcreteFog-${i}.jpg`)
}

const ConcreteFogPage = () => {
  return (
    <Layout isProjectPage>
      <SEO />

      <Hero>
        Concrete Fog
        <Image src={"ConcreteFog-thumbnail"} />
      </Hero>

      <TextColumns offset>
        <div></div>
        <div>10/2/18 - 11/20/18</div>
        <div>
          Concrete Fog represents a minimalistic approach to an equestrian
          farrier and horseshoeing facility. The project specified a forging
          area, stalls for horses, and a separate medical center. Concrete Fog
          is composed of curvilinear forms. The external skin is inspired by
          Tadao Ando’s concrete. The simplicity of the form and material is
          intended to draw attention toward other key elements of the project.
        </div>
      </TextColumns>

      <Image src={img[7]} />
      <StyledFlex>
        <StyledFlex>
          <p>
            The site shrouds the building with trees allowing for the
            differentiation between natural and artificial. The orientation of
            the building is placed from east to west. Concrete Fog is facing
            south to allow scenic views toward the open range of the horses’
            pasture area.
          </p>
        </StyledFlex>
        <StyledFlex />
      </StyledFlex>

      <ImageGrid>
        <Image src={img[0]} />
        <Image src={img[1]} />
        <Image src={img[10]} />
        <StyledFlex align="flex-end">
          <StyledFlex />
          <StyledFlex>
            The horse stalls are located in the bending curve which allow for
            the horses to have views. Placing rectangles on a curve made for odd
            geometry. The extra space that was created would be used to store
            hay and other supplies for the horses. The stalls are not connected
            to the curving wall instead there is a 1 foot gap and the roof is
            set back 2 feet. This allows the horses to be next to one another
            and socialize without being confined. The stalls have 3 windows that
            allow light to pass through during the morning, mid-day, and
            afternoon. The front facade of the stalls is monochromatic in color
            and material. The doors that allow passage are designed to be the
            same size as the concrete rectangles. The seam-line of the door is
            hidden in the break of the pattern. There is a roller track system
            to accommodate for the width of the door, the horses near the door,
            and allow for smooth movement. The roof is designed to support small
            plant overgrowth into the interior space.
          </StyledFlex>
        </StyledFlex>
      </ImageGrid>

      <Image src={img[6]} />
      <StyledFlex>
        <StyledFlex>
          <p>
            Specifically, the building adopts different personas according to
            the seasons. The minimalist materials work with the natural color
            cycles of the seasons to highlight the seasonal colors. Different
            seasonal elements - greenery, fallen ochre leaves, the bare of
            winter - contrast with the concrete to instill a deeper
            understanding of nature.
          </p>
        </StyledFlex>
        <StyledFlex />
      </StyledFlex>

      <ImageGrid>
        <Image src={img[8]} />
        <Image src={img[9]} />
        <Image src={img[2]} />
        <StyledFlex>
          <StyledFlex />
          <StyledFlex>
            The trees are placed in a grid pattern. The bottom of the trees
            canopy is meant to be 20-25 feet. This allows for ground level views
            to be clear of the concrete wall and the trunk of the tree. The
            ground is lush with weeping love-grass, as the wind and light begin
            to pass through it creates movement of warmth and nostalgia.
          </StyledFlex>
        </StyledFlex>
      </ImageGrid>

      <Image src={img[12]} />
      <TextColumns>
        <div>
          The entrance of the medical center is made up of a large intersecting
          wall that is met with a large door cutout. This is to help to visitors
          understand the entrance of the building. I wanted to create a sense of
          hierarchy that was understood yet simple. The transition space that
          separates the medical center from the curved concrete facade allows
          for passage that is open air and has natural daylight. As you walk
          into the building you are met at the lobby space that outlooks the
          pasture and frames the view. The lobby space was a consideration of
          clientele. I wanted to create a branding image from the exterior of
          the building, but also the interior as well. After doing research I
          learned that all horses, by nature, are claustrophobic to some degree
          they have evolved over time to be free roaming beings. This
          information lead to the inclusion of floor to ceiling windows. The
          interior spaces have large pocket sized doors that allow horses to be
          transferred to other rooms with ease.
        </div>
      </TextColumns>

      <Image src={img[11]} />
      <TextColumns>
        <div>
          The large intersecting wall has a staircase that leads to the rooftop.
          This area allows for an aerial view of the overall project. The tops
          is meant to hold grass this allows for a public space without the
          addition of chairs and tables, which would ruin the aesthetic. The
          grass is meant to supply a soft cushion.
        </div>
        <div>
          While touring horse facilities I noticed the qualities of gravel
          paths. As I walked, the feeling through my boots created a
          satisfaction that was inexpressible. Covering the dirt roads with
          gravel allowed for the paths to keep their undeveloped nature but made
          them clean cut while refraining from degrading the overall building.
        </div>
        <div>
          The forge area is open air that allows for natural ventilation. The
          separate spaces are divided by a low concrete wall that allows for
          horses to be tied up too. During inadequate weather the installment of
          side mount shading devices cover the workstations.
        </div>
      </TextColumns>

      <ImageGrid>
        <Image src={img[4]} />
        <Image src={img[5]} />
        <Image src={img[3]} />
      </ImageGrid>

      <div className={"drawingsHeader"}>Drawings</div>

      <div className={"drawing"}>
        <Image src={"ConcreteFog-Drawing-FloorPlan"} />
        <p>Floor plan</p>
      </div>

      <div className={"drawing"}>
        <Image src={"ConcreteFog-Drawing-SectionCut1"} />
        <p>Section cut 1</p>
      </div>

      <div className={"drawing"}>
        <Image src={"ConcreteFog-Drawing-SectionCut2"} />
        <p>Section cut 2</p>
      </div>

      <div className={"drawing"}>
        <Image src={"ConcreteFog-Drawing-Elevation"} />
        <p>South Elevation</p>
      </div>

      <div className={"drawing"}>
        <Image src={"ConcreteFog-Drawing-NorthWestElevation"} />
        <p>North Elevation</p>
      </div>
    </Layout>
  )
}

export default ConcreteFogPage
